import { Link } from "gatsby"
import React from "react"
import cevencoImg from "../images/cevenco.png"
import cptBwImg from "../images/cpt-bw.png"
import cptBwImgWebp from "../images/cpt-bw.webp"
import facebookSvg from "../images/facebook-square-brands.svg"
import linkedInSvg from "../images/linkedin-brands.svg"
import twitterSvg from "../images/twitter-square-brands.svg"

class Footer extends React.Component {
  render() {
    return (<>
      <footer>
        <div className="body gutter">
          <div className="footer-main">
            <div className="footer-about">
              <h4>About Tetley's Coaches</h4>
              <p>Established in 1949, Tetley's Coaches is Leeds' oldest coach company and has provided safe and reliable coach transport in Leeds, Yorkshire and nationally for over 70 years.</p>
              <p>
                <picture className="footer-cpt-logo" >
                  <source type="image/webp" srcSet={cptBwImgWebp} />
                  <img src={cptBwImg} alt="CPT member" />
                </picture>
              </p>

            </div>
            <div className="footer-coach-hire">
              <h4>Coach hire in Yorkshire</h4>
              <ul className="footer-list">
                <li><Link to="/">Coach hire Leeds</Link></li>
                <li><Link to="/coach-hire-bradford/">Coach hire Bradford</Link></li>
                <li><Link to="/coach-hire-halifax/">Coach hire Halifax</Link></li>
                <li><Link to="/coach-hire-harrogate/">Coach hire Harrogate</Link></li>
                <li><Link to="/coach-hire-huddersfield/">Coach hire Huddersfield</Link></li>
                <li><Link to="/coach-hire-wakefield/">Coach hire Wakefield</Link></li>
                <li><Link to="/coach-hire-york/">Coach hire York</Link></li>
              </ul>
            </div>
            <div className="footer-links">
              <h4>Other links</h4>
              <ul className="footer-list">
                <li><a href="https://portal.tetleyscoaches.co.uk/login">Customer portal</a></li>
                <li><Link to="/news/" title="News">News</Link></li>
                <li><Link to="/privacy-policy/" title="Privacy policy">Privacy Policy</Link></li>
                <li><Link to="/policies-documents/" title="Policies and Documents">Policies &amp; Documents</Link></li>
                <li><Link to="/sitemap/" title="Sitemap">Sitemap</Link></li>
                <li><a href="https://staff.tetleyscoaches.co.uk" rel="nofollow noopener noreferrer" title="Staff area">Staff area</a></li>
              </ul>
            </div>
          </div>
          <div className="company-info">
            <div className="footer__social">
              <a className="social-link" href="https://www.facebook.com/tetleyscoaches/"><img className="social-icon" src={facebookSvg} alt="Facebook" /></a>
              <a className="social-link" href="https://uk.linkedin.com/in/daniel-milnes-756ab795"><img className="social-icon" src={linkedInSvg} alt="LinkedIn" /></a>
              <a className="social-link" href="https://twitter.com/tetleyscoaches"><img className="social-icon" src={twitterSvg} alt="Twitter" /></a>
            </div>
            <p>Tetley's Motor Services Limited T/A Tetley's Coaches is a limited company registered in England and Wales.</p>
            <p>Registered number: 03309948. VAT Reg: GB-686 6377 74. Registered office: 76 Goodman Street, Leeds, LS10 1NY.</p>

          </div>
        </div>
      </footer>
      <div className="footer-strip">
        <div className="footer-strip-body gutter">
          <p>
            © Tetley's Coaches {new Date().getFullYear()}
          </p>
          <div className="footer-strip-credit">
            <span>Website by </span><a href="https://cevenco.co.uk" title="Cevenco"><img src={cevencoImg} alt="Cevenco" /></a>
          </div>
        </div>
      </div>
    </>)
  }
}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default Footer
